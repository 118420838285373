import { graphql } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import CtA from '../../components/CTA/CTA'
import * as BlogStyles from './blog.module.css'

function Blog({ data }) {

    return (
        <Layout>
            <Helmet>
                <title>Blog | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Blog</h1>
                <h2>All of the latest news, views and tips for self-care from the team here at the Brixworth Osteopathic Clinic.</h2>
            </Title>
            <section className={BlogStyles.listingSection}>
                <div className={BlogStyles.postsGrid}>
                    {
                        data.allMarkdownRemark.nodes.map(node => (
                            <div className={BlogStyles.card} key={node.frontmatter.id}>
                                <div className={BlogStyles.cardTop}>
                                    <img src={node.frontmatter.featuredImage} alt={node.frontmatter.featuredImageAlt} width="600" />
                                </div>
                                <h3 className={BlogStyles.title}>{node.frontmatter.title}</h3>
                                <div className={BlogStyles.date}>{node.frontmatter.date}</div>
                                <p className={BlogStyles.excerpt}>{node.excerpt}</p>
                                <div className={BlogStyles.link}>
                                    <CtA to={`/blog/${node.frontmatter.slug}`} text="Read more" />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    featuredImage
                    featuredImageAlt
                    date(formatString: "DD MMMM YYYY")
                    title
                    slug
                }
                id
                excerpt(pruneLength: 75)
            }
        }
    }
`

export default Blog